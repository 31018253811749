import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getCategories = createAsyncThunk("categories/AllCategories", async () => {
  const token = Cookies.get(`${process.env.REACT_APP_TOKEN_NAME}`)
  const res = await axios.get(`${process.env.REACT_APP_SERVER_URL}/Products/AllCategories`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  }
  )
  return res.data.data
})

const initialState = {
  isLoading: true,
  categories: null
}

export const categoriesSlice = createSlice({
  name: 'categories',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCategories.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getCategories.fulfilled, (state, { payload }) => {
      state.categories = payload
      state.isLoading = false
    })
    builder.addCase(getCategories.rejected, (state, action) => {
      state.isLoading = true
      if (action.payload) {
        console.log(action.payload.errorMessage)
      } else {
        console.log(action.error.message)
      }
    })
  },
})

export default categoriesSlice.reducer