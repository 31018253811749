import LoadingRow from "../LoadingRow/LoadingRow"

export const LoadingTable = () => {
  return (
    <>
      {
        new Array(Math.floor(Math.random() * 10)).fill(0).map((_, i) => (
          <LoadingRow key={i} />
        ))
      }
    </>
  )
}
