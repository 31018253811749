import { Box } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import Forms from "../../forms/Forms";
import DataBox from "./DataBox";

const UserDetails = () => {
  const { userData } = useContext(AppContext)
  const { arName, enName, arDescription, enDescription, email, phone, client, commercialRegistrationNo, isHidden } = userData
  const { t } = useTranslation()
  return (
    <Box className={`grid jcs aic g30`}>
      <DataBox data={t("lang") === "ar" ? arName : enName} title={t("name")} />
      <DataBox data={t("lang") === "ar" ? arDescription : enDescription} title={t("description")} />
      <Box className={`flex jcs aic g20 flex_wrap`}>
        <DataBox data={email} title={t("email")} />
        <DataBox data={phone} title={t("phone")} />
      </Box>
      <Box className={`flex jcs aic g20 flex_wrap`}>
        {client &&
          (
            <DataBox data={t("lang") === "ar" ? client.clientType.arName : client.clientType.enName} title={t("clientType")} />
          )
        }
        {commercialRegistrationNo &&
          (
            <DataBox data={commercialRegistrationNo} title={t("commercialRegistrationNo")} />
          )
        }
        <DataBox data={`${isHidden ? t("hidden") : t("visibiled")}`} title={t("status")} />
      </Box>
      {!isHidden ? <Forms type={"hide_user"} userId={userData._id} /> :
        <Forms type={"unhide_user"} userId={userData._id} />}
    </Box>
  )
}

export default UserDetails