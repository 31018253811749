import { Box, Typography } from "@mui/material"
import styles from "./Title.module.scss"

const Title = ({ title, icon }) => {
  return (
    <Box className={`${styles.title} flex jcc aic g10 center_rel_x`}>
      {icon}
      <Typography variant="h4" className="fw700 tac" >{title}</Typography>
    </Box>
  )
}

export default Title