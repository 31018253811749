import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./authSlice"
import categoriesReducer from "./categoriesSlice"
import categoryDetailsReducer from "./categoryDetailsSlice"
import complaintsReducer from "./complaintsSlice"
import contactsReducer from "./contactsSlice"
import mostPurchasingUsersReducer from "./mostPurchasingUsersSlice"
import mostSellingUsersReducer from "./mostSellingUsersSlice"
import mostSoldProductsReducer from "./mostSoldProductsSlice"
import productSellersReducer from "./productSellersSlice"
import productsReducer from "./productsSlice"
import userTypesReducer from "./userTypesSlice"
import usersReducer from "./usersSlice"

export const store = configureStore({
  reducer: {
    "auth":authReducer,
    "users":usersReducer,
    "mostSoldProducts":mostSoldProductsReducer,
    "contacts":contactsReducer,
    "complaints":complaintsReducer,
    "products":productsReducer,
    "mostSellingUsers":mostSellingUsersReducer,
    "mostPurchasingUsers":mostPurchasingUsersReducer,
    "categories":categoriesReducer,
    "userTypes":userTypesReducer,
    "productSellers":productSellersReducer,
    "categoryDetails":categoryDetailsReducer
  },
})