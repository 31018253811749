import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getUsers = createAsyncThunk("users/getUsers",async(args)=>{
  const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
  const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/Admin/AllUsers`,{start:args?.start ,count:args?.count },{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return { data: res.data.data, index: args.start, last: res.data.data.length < 10 }
})


const initialState = {
  users: null,
  isLoading:true,
  index: 0,
  last: false
}

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      if (payload.index > 0) {
        state.users.push(...payload.data)
      } else {
        state.users = payload.data
      }
      state.index = payload.index
      state.last = payload.last
      state.isLoading = false
    })
    builder.addCase(getUsers.rejected, (state, action) => {
      state.isLoading = true
      if (action.payload) {
        console.log(action.payload.errorMessage)
      } else {
        console.log(action.error.message)
      }
    })
  }
})


export default usersSlice.reducer