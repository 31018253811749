import { Box, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadButton from "../components/LoadButton/LoadButton";
import { PrimaryButton } from "../muiCustomize/PrimaryButton";
import { PrimaryTextField } from "../muiCustomize/PrimaryTextField";

const LoginForm = ({ loading, formik }) => {
  const { t } = useTranslation()
  return (
    <Box className={`grid jcs aic g30`}>
      <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
        <Typography variant="h6">{t("forms.username.label")}</Typography>
        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="text"
          id="username"
          name="username"
          value={formik.values.username}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
      </Box>
      <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
        <Typography variant="h6">{t("forms.password.label")}</Typography>
        <PrimaryTextField
          fullWidth
          variant="outlined"
          type="password"
          id="password"
          name="password"
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.password && formik.errors.password}
        />
      </Box>
      <Box className={`grid jcs aic g20`}>
        <LoadButton loading={loading}>
          <PrimaryButton type={"submit"}>{t("forms.login.button.text")}</PrimaryButton>
        </LoadButton>
      </Box>
    </Box>
  )
}

export default LoginForm
