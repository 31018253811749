import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SecondaryButton } from "../../muiCustomize/SecondaryButton"
import { getContacts } from "../../store/contactsSlice"
import { LoadingTable } from "../LoadingTable/LoadingTable"
import styles from "./ContactsTable.module.scss"
import Row from "./Row"

const ContactsTable = () => {
  const { t } = useTranslation()
  const { contacts, isLoading, index, last } = useSelector((state) => state.contacts)
  const dispatch = useDispatch()
  const handlePagination = () => {
    dispatch(getContacts({ start: index + 1, count: 10 }))
  }
  return (
    <Box className={`grid jcs aic g30 ${styles.contacts_table}`}>
      <Row head={true} />
      {
        isLoading ? (<LoadingTable />) : (contacts && contacts.length > 0) ? contacts.map((contact, i) => (
          <Row contact={contact} key={i} index={i} />
        )) : (<Typography variant="h5" className="tac fw700" sx={{ color: (theme) => theme.palette.gray }} >{t("contacts.no_contacts.title")}</Typography>)
      }
      {!last && <SecondaryButton onClick={handlePagination}>{t("view_more.button")}</SecondaryButton>}
    </Box>
  )
}

export default ContactsTable