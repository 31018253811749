import { EditRounded } from "@mui/icons-material";
import { Box, Skeleton, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { AppContext } from "../../context/AppContext";
import Forms from "../../forms/Forms";
import { handleFileOrPath } from "../../functions/handleFileOrPath";
import { PrimaryButton } from "../../muiCustomize/PrimaryButton";
import { getCategoryDetails } from "../../store/categoryDetailsSlice";
import { getProductSellers } from "../../store/productSellersSlice";
import SellersTable from "../SellersTable/SellersTable";
import styles from "./ProductsTable.module.scss";

const ProductDetails = () => {
  const { productData, handleOpenEditProductModal } = useContext(AppContext)
  const { arName, enName, isRemoved, arDescription, enDescription, imageURL, category } = productData
  const { t } = useTranslation()
  const { categoryDetails } = useSelector((state) => state.categoryDetails)
  const dispatch = useDispatch()
  const { productSellers, isLoading, index, last } = useSelector((state) => state.productSellers)
  const { setViewMoreLoading } = useContext(AppContext)

  const handlePagination = async (id) => {
    setViewMoreLoading(true)
    await dispatch(getProductSellers({ id, start: index + 1, count: 10 }))
    setViewMoreLoading(false)
  }

  useEffect(() => {
    if (category) {
      dispatch(getCategoryDetails({ categoryId: category }))
    }
  }, [dispatch, category])

  useEffect(() => {
    if (productData) {
      dispatch(getProductSellers({ id: productData._id, start: 0, count: 10 }))
    }
  }, [dispatch, productData, index])

  return (
    <Box className={`${styles.details} grid jcs aic g30`}>
      <Box className={`${styles.data_image_box} flex jcc aic`}>
        <LazyLoadImage src={`${handleFileOrPath(imageURL)}`} alt={"product"} />
      </Box>
      <Box className={`${styles.details_box} grid jcs aic g30`}>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("name")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{t("lang") === "ar" ? arName : enName}</Typography>
        </Box>

        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("description")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{t("lang") === "ar" ? arDescription : enDescription}</Typography>
        </Box>

        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          {
            !categoryDetails ? (<>
              <Skeleton variant="text" sx={{ width: "100%" }} />
              <Skeleton variant="text" sx={{ width: "100%" }} />
            </>) : categoryDetails && (
              <>
                <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("category")} : </Typography>
                <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{t("lang") === "ar" ? categoryDetails.arName : categoryDetails.enName}</Typography>
              </>
            )
          }
        </Box>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("status")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{isRemoved ? t("hidden") : t("visibiled")}</Typography>
        </Box>
      </Box>
      <Box className={`flex jcsb aic g20 flex_wrap`}>
        <PrimaryButton onClick={() => handleOpenEditProductModal(productData)} className={`flex jcc aic g10`}>
          <Typography variant="h6">{t("edit.button")}</Typography>
          <EditRounded />
        </PrimaryButton>
        {!isRemoved ? <Forms type={"hide_product"} proId={productData._id} /> :
          <Forms type={"unhide_product"} proId={productData._id} />}
      </Box>
      {productData && <SellersTable productSeller={productSellers} isLoading={isLoading} last={last} handlePagination={handlePagination} id={productData._id} />}
    </Box>
  )
}

export default ProductDetails