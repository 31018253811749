import { Box, Modal, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../context/AppContext';
import Forms from '../forms/Forms';
import "./Modals.scss";

export const EditProductModal = () => {
  const { openEditProductModal, handleCloseEditProductModal } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Modal
      open={openEditProductModal}
      onClose={handleCloseEditProductModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`modal br10 pad20 center_abs_x_y grid jcs aic g30`}>
        <Typography variant='h5' className={`fw700 tac`} sx={{ color: (theme) => theme.palette.primary.main }} >{t("edit_product.title")}</Typography>
        <Forms type={"edit_product"} />
      </Box>
    </Modal>
  )
}
