import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../context/AppContext"
import { SecondaryButton } from "../../muiCustomize/SecondaryButton"
import LoadButton from "../LoadButton/LoadButton"
import { LoadingTable } from "../LoadingTable/LoadingTable"
import styles from "./ProductsTable.module.scss"
import Row from "./Row"

const ProductsTable = ({ handlePagination, products, isLoading, last }) => {
  const { t } = useTranslation()
  const { viewMoreLoading } = useContext(AppContext)

  return (
    <Box className={`grid jcs aic g30 ${styles.products_table}`}>
      <Row head={true} />
      {
        isLoading ? (<LoadingTable />) : (products && products.length > 0) ? products.map((product, i) => (
          <Row product={product} key={i} index={i} />
        )) : (<Typography variant="h5" className="tac fw700" sx={{ color: (theme) => theme.palette.gray }} >{t("products.no_products.title")}</Typography>)
      }
      {!last && <LoadButton loading={viewMoreLoading}>
        <SecondaryButton onClick={handlePagination}>{t("view_more.button")}</SecondaryButton>
      </LoadButton>}
    </Box>
  )
}

export default ProductsTable