import { BadgeRounded, MailRounded, PhoneIphoneRounded } from "@mui/icons-material"
import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import { useContext } from "react"
import { useTranslation } from 'react-i18next'
import { AppContext } from "../../context/AppContext"
import { handleFileOrPath } from "../../functions/handleFileOrPath"
import styles from "./UsersTable.module.scss"

const Row = ({ head, user, index }) => {
  const { t } = useTranslation()
  const { handleOpenViewUserDetails } = useContext(AppContext)
  const mdSize = useMediaQuery("(max-width:992px)")
  const smSize = useMediaQuery("(max-width:768px)")

  const handleViewUserDetails = () => {
    handleOpenViewUserDetails(user)
  }

  return head ? (
    <Paper className={`grid jcs aic pad20 br10 ${styles.row} ${styles.head_row} `}>
      <Typography variant="h6" className={`fw700`}># Id</Typography>
      <Box className={`flex aic g5`}>
        {!mdSize && <BadgeRounded />}
        <Typography variant="h6" className={`fw700`}>{t("name")}</Typography>
      </Box>
      {!smSize && (
        <Box className={`flex aic g5`}>
          {!mdSize && <MailRounded />}
          <Typography variant="h6" className={`fw700`}>{t("email")}</Typography>
        </Box>
      )}
      <Box className={`flex aic g5`}>
        {!mdSize && <PhoneIphoneRounded />}
        <Typography variant="h6" className={`fw700`}>{t("phone")}</Typography>
      </Box>
    </Paper>
  ) : (
    <Paper onClick={handleViewUserDetails} className={`${user.isHidden && styles.hidden} ${styles.row} pad20 br10 grid jcs aifs`}>
      <Typography variant="h6">{`# ${index + 1}`}</Typography>
      <Box className={`flex jcfs aic g10 ${styles.user_name}`}>
        {!mdSize && <Box className={`flex jcc aic ${styles.user_image_box}`} sx={{ backgroundImage: `url('${handleFileOrPath(user.cover)}')` }} />}
        <Typography variant="h6">{t("lang") === "ar" ? user.arName : user.enName}</Typography>
      </Box>
      {!smSize && <Typography variant="h6">{user.email}</Typography>}
      <Typography variant="h6">{user.phone}</Typography>
    </Paper>
  )
}

export default Row