import { PhoneCallbackRounded } from '@mui/icons-material'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import ContactsTable from '../../components/ContactsTable/ContactsTable'
import Title from '../../components/Title/Title'
import { AppContext } from "../../context/AppContext"
import Forms from '../../forms/Forms'
import { PrimaryBox } from '../../muiCustomize/PrimaryBox'
import { PrimaryContainer } from '../../muiCustomize/PrimaryContainer'
import { getContacts } from '../../store/contactsSlice'

const ContactsSection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { startDate, endDate } = useContext(AppContext)
  useEffect(() => {
    dispatch(getContacts({ startDate, endDate, start: 0, count: 10 }))
  }, [dispatch, startDate, endDate])
  return (
    <PrimaryBox>
      <PrimaryContainer className={`grid jcs aic g30`}>
        <Forms type={"handle_report_dates"} />
        <Title title={t("contacts.title")} icon={<PhoneCallbackRounded />} />
        <ContactsTable />
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default ContactsSection