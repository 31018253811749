import { DateRangeRounded, DriveFileRenameOutlineRounded, ReportRounded } from '@mui/icons-material'
import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppContext'
import { handleDate } from '../../functions/handleDate'
import { handleFileOrPath } from '../../functions/handleFileOrPath'
import styles from "./ComplaintsTable.module.scss"


const Row = ({ head, complaint, index }) => {
  const { t } = useTranslation()
  const { handleOpenViewComplaintDetails } = useContext(AppContext)
  const mdSize = useMediaQuery("(max-width:992px)")
  const smSize = useMediaQuery("(max-width:768px)")

  const handleViewComplaint = () => {
    handleOpenViewComplaintDetails(complaint)
  }

  return head ? (
    <Paper className={`grid jcs aifs pad20 br10 ${styles.row} ${styles.head_row} `}>
      <Typography variant="h6" className={`fw700`}># Id</Typography>
      <Box className={`flex aic g5`}>
        {!mdSize && <DriveFileRenameOutlineRounded />}
        <Typography variant="h6" className={`fw700`}>{t("name")}</Typography>
      </Box>
      <Box className={`flex aic g5`}>
        {!mdSize && <ReportRounded />}
        <Typography variant="h6" className={`fw700`}>{t("complaint")}</Typography>
      </Box>
      {!smSize && <Box className={`flex aic g5`}>
        {!mdSize && <DateRangeRounded />}
        <Typography variant="h6" className={`fw700`}>{t("date")}</Typography>
      </Box>}
    </Paper>
  ) : (
    <Paper onClick={handleViewComplaint} className={`${styles.row} pad20 br10 grid jcs aifs`}>
      <Typography variant="h6">{`# ${index + 1}`}</Typography>
      <Box className={`flex jcfs aic g10 ${styles.user_name}`}>
        {!smSize && <Box className={`flex jcc aic ${styles.user_image_box}`} sx={{ backgroundImage: `url('${handleFileOrPath(complaint.user.cover)}')` }} />}
        <Typography variant="h6">{t("lang") === "ar" ? complaint.user.arName : complaint.user.enName}</Typography>
      </Box>
      <Typography variant="h6">{t("lang") === "ar" ? complaint.message : complaint.message}</Typography>
      {!smSize && <Typography variant="h6">{handleDate(complaint.createdAt)}</Typography>}
    </Paper>
  )
}

export default Row