import { DateRangeRounded, DriveFileRenameOutlineRounded, MessageRounded } from '@mui/icons-material'
import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { AppContext } from '../../context/AppContext'
import { handleDate } from '../../functions/handleDate'
import styles from "./ContactsTable.module.scss"


const Row = ({ head, contact, index }) => {
  const { t } = useTranslation()
  const { handleOpenViewContactDetails } = useContext(AppContext)
  const mdSize = useMediaQuery("(max-width:992px)")
  const smSize = useMediaQuery("(max-width:768px)")

  const handleViewComplaint = () => {
    handleOpenViewContactDetails(contact)
  }

  return head ? (
    <Paper className={`grid jcs aic pad20 br10 ${styles.row} ${styles.head_row} `}>
      <Typography variant="h6" className={`fw700`}># Id</Typography>
      <Box className={`flex aic g5`}>
        {!mdSize && <DriveFileRenameOutlineRounded />}
        <Typography variant="h6" className={`fw700`}>{t("name")}</Typography>
      </Box>
      <Box className={`flex aic g5`}>
        {!mdSize && <MessageRounded />}
        <Typography variant="h6" className={`fw700`}>{t("message")}</Typography>
      </Box>
      {!smSize && <Box className={`flex aic g5`}>
        {!mdSize && <DateRangeRounded />}
        <Typography variant="h6" className={`fw700`}>{t("date")}</Typography>
      </Box>}
    </Paper>
  ) : (
    <Paper onClick={handleViewComplaint} className={`${styles.row} pad20 br10 grid jcs aifs`}>
      <Typography variant="h6">{`# ${index + 1}`}</Typography>
      <Typography variant="h6">{contact.name}</Typography>
      <Typography variant="h6">{contact.message}</Typography>
      {!smSize && <Typography variant="h6">{handleDate(contact.createdAt)}</Typography>}
    </Paper>
  )
}

export default Row