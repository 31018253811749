import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import ProductsTable from "../../components/ProductsTable/ProductsTable"
import { AppContext } from "../../context/AppContext"
import { getMostSoldProducts } from "../../store/mostSoldProductsSlice"

const MostSoldProductsSection = () => {
  const dispatch = useDispatch()
  const { mostSoldProducts, isLoading, index, last } = useSelector((state) => state.mostSoldProducts)
  const { startDate, endDate, setViewMoreLoading } = useContext(AppContext)

  const handlePagination = () => {
    setViewMoreLoading(true)
    dispatch(getMostSoldProducts({ startDate, endDate, start: index + 1, count: 10 }))
    setViewMoreLoading(false)
  }

  useEffect(() => {
    dispatch(getMostSoldProducts({ startDate, endDate, start: 0, count: 10 }))
  }, [dispatch, startDate, endDate])

  return (
    <ProductsTable handlePagination={handlePagination} products={mostSoldProducts} isLoading={isLoading} last={last} />
  )
}

export default MostSoldProductsSection