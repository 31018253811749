import { LogoutRounded, Menu } from "@mui/icons-material";
import { AppBar, Box, Tooltip, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import avatar from "../../assets/images/avatar.png";
import { AppContext } from '../../context/AppContext';
import { PrimaryContainer } from "../../muiCustomize/PrimaryContainer";
import { SecondaryIconButton } from "../../muiCustomize/SecondaryIconButton";
import { SpecialIconButton } from "../../muiCustomize/SpecialIconButton";
import { logout } from "../../store/authSlice";
import Logo from "../Logo/Logo";
import styles from "./Header.module.scss";
import LanguagesMenu from "./LanguagesMenu";

const Header = () => {
  const { handleDrawerOpen, openDrawer } = useContext(AppContext)
  const { t } = useTranslation()
  const { signed, user } = useSelector((state) => state.auth)
  const mdSize = useMediaQuery("(max-width:992px)")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleLogout = () => {
    dispatch(logout())
    navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`)
  }

  return (
    <AppBar className={`${styles.header}`} open={openDrawer} sx={{ zIndex: openDrawer ? "1000" : "1001" }}>
      <PrimaryContainer className={`flex jcsb aic g30 ${styles.header_contain}`}>
        {signed && <Box className={`flex jcsb aic g10`}>
          <SpecialIconButton
            onClick={handleDrawerOpen}
          >
            <Menu />
          </SpecialIconButton>
          {!mdSize && <>
            <Tooltip title={"Logout"}>
              <SecondaryIconButton onClick={handleLogout}>
                <LogoutRounded />
              </SecondaryIconButton>
            </Tooltip>
            <LanguagesMenu />
          </>}

        </Box>}
        {!signed && <LanguagesMenu />}
        <Box className={`flex jcc aic g10`}>
          <Logo />
          {!mdSize && <Typography variant="h5" className={`fw700 tac`} noWrap>
            {t("header.title")}
          </Typography>}
        </Box>
        {
          signed && (
            <Box className={`flex jcc aic g10`}>
              {mdSize ?
                <>
                  <Tooltip title={user.name}>
                    <Box className={`flex jcc aic ${styles.avatar_box}`}>
                      <LazyLoadImage src={avatar} alt={"avatar"} />
                    </Box>
                  </Tooltip>
                </> :
                <>
                  <Typography variant="h5" className={`tac`} noWrap>
                    {user.username}
                  </Typography> <Box className={`flex jcc aic ${styles.avatar_box}`}>
                    <LazyLoadImage src={avatar} alt={"avatar"} />
                  </Box>
                </>}
            </Box>
          )
        }
      </PrimaryContainer>
    </AppBar>
  )
}

export default Header
