import { Box, Tabs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AppContext } from '../../context/AppContext';
import Forms from '../../forms/Forms';
import { PrimaryBox } from '../../muiCustomize/PrimaryBox';
import { PrimaryContainer } from '../../muiCustomize/PrimaryContainer';
import { PrimaryTab } from '../../muiCustomize/PrimaryTab';
import MostPurchasingUsersSection from '../../sections/MostPurchasingUsersSection/MostPurchasingUsersSection';
import MostSellingUsersSection from '../../sections/MostSellingUsersSection/MostSellingUsersSection';
import MostSoldProductsSection from '../../sections/MostSoldProductsSection/MostSoldProductsSection';

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </Box>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const AnalysisReportsOptions = () => {
  const { setOption } = useContext(AppContext)
  const [value, setValue] = useState(0)
  const { t } = useTranslation()

  const handleChange = (event, newValue) => {
    setOption(newValue)
    setValue(newValue);
  };

  return (
    <PrimaryBox>
      <PrimaryContainer className={` grid jcs aic g50`}>
        <Typography variant='h4' className={`tac`}>{t("analysis_reports.title")}</Typography>
        <Box className={`grid jcs aic g30`}>
          <Forms type={"handle_report_dates"} />
          <Tabs variant='scrollable' scrollButtons="auto" value={value} onChange={handleChange} aria-label="scrollable auto tabs example">
            <PrimaryTab label={t("analysis_reports.most_sold_products.tab")} {...a11yProps(0)} />
            <PrimaryTab label={t("analysis_reports.most_purchased_users.tab")} {...a11yProps(1)} />
            <PrimaryTab label={t("analysis_reports.most_selling_users.tab")} {...a11yProps(2)} />
          </Tabs>
          <CustomTabPanel value={value} index={0}>
            <MostSoldProductsSection />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <MostPurchasingUsersSection />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2}>
            <MostSellingUsersSection />
          </CustomTabPanel>
        </Box>
      </PrimaryContainer>
    </PrimaryBox>
  );
}

export default AnalysisReportsOptions