import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../context/AppContext";
import { handleDate } from "../../functions/handleDate";
import styles from "./ContactsTable.module.scss";

const ContactDetails = () => {
  const { contactData } = useContext(AppContext)
  const { createdAt, message, name, email } = contactData
  const { t } = useTranslation()
  return (
    <Box className={`${styles.details} grid jcs aic g30`}>
      <Box className={`${styles.details_box} grid jcs aifs g30`}>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("name")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{name}</Typography>
        </Box>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("email")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{email}</Typography>
        </Box>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("date")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{handleDate(createdAt)}</Typography>
        </Box>
      </Box>
      <Box className={`${styles.details_box} flex jcs aifs g30`}>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("message")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{message}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactDetails