import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getProductSellers = createAsyncThunk("productSellers/getProductSellers",async(args)=>{
  const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
  const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/Admin/ProductSellers`,{id:args?.id,start:(args?.start)*10 || 0,count:10 },{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return { data: res.data.data, index: args.start, last: res.data.data.length < 10 }
})


const initialState = {
  productSellers: null,
  isLoading:true,
  index:0,
  last:false
}

export const productSellersSlice = createSlice({
  name: 'productSellers',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getProductSellers.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getProductSellers.fulfilled, (state, { payload }) => {
      if (payload.index > 0) {
        state.productSellers.push(...payload.data)
      } else {
        state.productSellers = payload.data
      }
      state.index = payload.index
      state.last = payload.last
      state.isLoading = false
    })
    builder.addCase(getProductSellers.rejected, (state, action) => {
      state.isLoading = true
      if (action.payload) {
        console.log(action.payload.errorMessage)
      } else {
        console.log(action.error.message)
      }
    })
  }
})


export default productSellersSlice.reducer