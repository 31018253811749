import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../context/AppContext"
import { SecondaryButton } from "../../muiCustomize/SecondaryButton"
import LoadButton from "../LoadButton/LoadButton"
import { LoadingTable } from "../LoadingTable/LoadingTable"
import styles from "./MostUsersTable.module.scss"
import Row from "./Row"

const MostUsersTable = ({ handlePagination, users, isLoading, last }) => {
  const { t } = useTranslation()
  const { viewMoreLoading } = useContext(AppContext)
  return (
    <Box className={`grid jcs aic g30 ${styles.users_table}`}>
      <Row head={true} />
      {
        isLoading ? (<LoadingTable />) : (users && users.length > 0) ? users.map((user, i) => (
          <Row user={user} key={i} index={i} />
        )) : (<Typography variant="h5" className="tac fw700" sx={{ color: (theme) => theme.palette.gray }} >{t("users.no_users.title")}</Typography>)
      }
      {!last && (
        <LoadButton loading={viewMoreLoading}>
          <SecondaryButton onClick={handlePagination}>{t("view_more.button")}</SecondaryButton>
        </LoadButton>
      )}
    </Box>
  )
}

export default MostUsersTable