import { Box, Typography } from '@mui/material'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useNavigate } from 'react-router-dom'
import errorImage from "../../assets/images/error.jpg"
import { PrimaryBox } from '../../muiCustomize/PrimaryBox'
import { PrimaryButton } from '../../muiCustomize/PrimaryButton'
import { PrimaryContainer } from '../../muiCustomize/PrimaryContainer'
import styles from "./ErrorSection.module.scss"

const ErrorSection = () => {
  const navigate = useNavigate()
  return (
    <PrimaryBox>
      <PrimaryContainer className={`${styles.error_section_contain} grid jcs aic g30`}>
        <Typography variant={"h1"} sx={{ color: (theme) => theme.palette.primary.main }} className={`tac`}>404</Typography>
        <Box className={`relative ${styles.error_image_box} flex jcc aic`}>
          <LazyLoadImage src={errorImage} alt={"error"} />
        </Box>
        <Box className={`flex jcc aic`}>
          <PrimaryButton sx={{ width: "fit-content" }} onClick={() => navigate(`${process.env.REACT_APP_HOME_ROUTE}`)}>Go Back</PrimaryButton>
        </Box>
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default ErrorSection
