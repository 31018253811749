import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { SecondaryButton } from "../../muiCustomize/SecondaryButton"
import { getComplaints } from "../../store/complaintsSlice"
import { LoadingTable } from "../LoadingTable/LoadingTable"
import styles from "./ComplaintsTable.module.scss"
import Row from "./Row"

const ComplaintsTable = () => {
  const { t } = useTranslation()
  const { complaints, isLoading, index, last } = useSelector((state) => state.complaints)
  const dispatch = useDispatch()
  const handlePagination = () => {
    dispatch(getComplaints({ start: index + 1, count: 10 }))
  }
  return (
    <Box className={`grid jcs aic g30 ${styles.complaints_table}`}>
      <Row head={true} />
      {
        isLoading ? (<LoadingTable />) : (complaints && complaints.length > 0) ? complaints.map((complaint, i) => (
          <Row complaint={complaint} key={i} index={i} />
        )) : (<Typography variant="h5" className="tac fw700" sx={{ color: (theme) => theme.palette.gray }} >{t("complaints.no_complaints.title")}</Typography>)
      }
      {!last && <SecondaryButton onClick={handlePagination}>{t("view_more.button")}</SecondaryButton>}
    </Box>
  )
}

export default ComplaintsTable