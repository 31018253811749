import { Box, Switch, Typography, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import styles from "./Header.module.scss";

const LanguagesMenu = () => {
    const [checked, setChecked] = useState(true);
    const { t } = useTranslation()

    const handleArabicLanguage = () => {
        i18n.changeLanguage("ar")
        localStorage.setItem("dagagino_dashboard_lang", "ar")
        document.documentElement.lang = "ar";
        document.documentElement.dir = "rtl";
    }

    const handleEnglishLanguage = () => {
        i18n.changeLanguage("en")
        localStorage.setItem("dagagino_dashboard_lang", "en")
        document.documentElement.lang = "en";
        document.documentElement.dir = "ltr";
    }

    const handleSwitch = (event) => {
        setChecked(event.target.checked);
        if (!event.target.checked) {
            handleEnglishLanguage()
        } else {
            handleArabicLanguage()
        }
    }

    const LangSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 42,
        height: 26,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 2,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(16px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#33cf4d',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 22,
            height: 22,
        },
        '& .MuiSwitch-track': {
            borderRadius: 26 / 2,
            backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

    useEffect(() => {
        if (localStorage.getItem("dagagino_dashboard_lang")) {
            let lang = localStorage.getItem("dagagino_dashboard_lang")
            if (lang === "ar") {
                setChecked(true)
            } else {
                setChecked(false)
            }
        }
    }, [])

    return (
        <Box className={`${styles.header_languages_menu} flex jcc aic g10`}>
            {
                t("lang") === "ar" ? (
                    <>
                        <Typography>عربى</Typography>
                        <LangSwitch onChange={handleSwitch} checked={checked} />
                        <Typography>English</Typography>
                    </>
                ) : (
                    <>
                        <Typography>English</Typography>
                        <LangSwitch onChange={handleSwitch} checked={checked} />
                        <Typography>عربى</Typography>
                    </>
                )
            }
        </Box>
    )
}

export default LanguagesMenu
