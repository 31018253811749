
import { Box } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import logoEnglish from "../../assets/images/logo.png"
import styles from "./Logo.module.scss"

const Logo = () => {
  return (
    <Box to={`${process.env.NEXT_PUBLIC_HOME_ROUTE}`} className={`${styles.logo} flex jcc aic`}>
      <LazyLoadImage src={logoEnglish} alt={"logo"} />
    </Box>
  )
}

export default Logo
