import { Box } from "@mui/material";
import { useContext } from "react";
import { FileUploader } from "react-drag-drop-files";
import { AppContext } from "../../context/AppContext";
import LoadUploadingImage from "../LoadUploadingImage/LoadUploadingImage";

const fileTypes = ["JPG", "PNG", "GIF", "jpeg"];

const UploadImage = ({ type }) => {
  const { setAvatarForRegister, loadingUploading, setLoadingUploading, setAvatarForChange, setProductImageForEdit, setProductImageForCreate } = useContext(AppContext)
  const handleChange = async (file) => {
    setLoadingUploading(true)
    if (type === "register") {
      setAvatarForRegister(file);
    } else if (type === "change_avatar") {
      setAvatarForChange(file)
    } else if (type === "edit_product") {
      setProductImageForEdit(file)
    } else if (type === "add_product") {
      setProductImageForCreate(file)
    }
    setLoadingUploading(false)
  };
  return (
    <Box className={`flex jcs aic`}>
      <LoadUploadingImage loading={loadingUploading}>
        <FileUploader handleChange={handleChange} name="file" types={fileTypes} />
      </LoadUploadingImage>
    </Box>
  )
}

export default UploadImage
