import { Inventory2Rounded } from "@mui/icons-material"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import ProductsTable from "../../components/ProductsTable/ProductsTable"
import Title from "../../components/Title/Title"
import { AppContext } from "../../context/AppContext"
import { PrimaryBox } from "../../muiCustomize/PrimaryBox"
import { PrimaryContainer } from "../../muiCustomize/PrimaryContainer"
import { getProducts } from "../../store/productsSlice"
import styles from "./ProductsSection.module.scss"

const ProductsSection = () => {
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { products, isLoading, index, last } = useSelector((state) => state.products)
  const { setViewMoreLoading } = useContext(AppContext)

  const handlePagination = async () => {
    setViewMoreLoading(true)
    await dispatch(getProducts({ start: index + 1, count: 10 }))
    setViewMoreLoading(false)
  }

  useEffect(() => {
    dispatch(getProducts({ start: 0, count: 10 }))
  }, [dispatch])

  return (
    <PrimaryBox>
      <PrimaryContainer className={`${styles.products_section_contain} grid jcs aic g30`}>
        <Title icon={<Inventory2Rounded />} title={t("products.title")} />
        <ProductsTable handlePagination={handlePagination} products={products} isLoading={isLoading} last={last} />
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default ProductsSection