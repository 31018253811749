import { AdminPanelSettingsRounded } from "@mui/icons-material"
import { Box, Typography } from "@mui/material"
import Forms from "../../forms/Forms"
import styles from "./LoginSection.module.scss"

const LoginSection = () => {
  return (
    <Box className={`br10 grid jcs aic g30  ${styles.login_section} pad20`}>
      <Box className={`flex jcc aic g10 ${styles.login_title}`}>
        <AdminPanelSettingsRounded />
        <Typography variant="h5" className="fw700 tac" sx={{ color: (theme) => theme.palette.primary.main }}>Login To Dashboard</Typography>
      </Box>
      <Forms type={"dashboard_login"} />
    </Box>
  )
}

export default LoginSection