import axios from "axios";
import { useFormik } from "formik";
import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';
import { AppContext } from "../context/AppContext";
import { handleAlert } from "../functions/handleAlert";
import { handleStartDateAndEndDate } from "../functions/handleStartDateAndEndDate";
import { login } from "../store/authSlice";
import { getComplaints } from "../store/complaintsSlice";
import { getContacts } from "../store/contactsSlice";
import { getMostPurchasingUsers } from "../store/mostPurchasingUsersSlice";
import { getMostSellingUsers } from "../store/mostSellingUsersSlice";
import { getMostSoldProducts } from "../store/mostSoldProductsSlice";
import { getProducts } from "../store/productsSlice";
import { getUsers } from "../store/usersSlice";
import AddProductForm from "./AddProductForm";
import EditProductForm from "./EditProductForm";
import "./Forms.scss";
import HandleReportDateForm from "./HandleReportDateForm";
import HideProductForm from "./HideProductForm";
import HideUserForm from "./HideUserForm";
import LoginForm from "./LoginForm";
import UnHideProductForm from "./UnHideProductForm";
import UnHideUserForm from "./UnHideUserForm";

const Forms = ({ type, proId, userId }) => {
  const server_url = process.env.REACT_APP_SERVER_URL
  const [loading, setLoading] = useState(false)
  const { token } = useSelector((state) => state.auth)
  const { option, setPages, productData, handleCloseViewProductDetails, handleCloseEditProductModal, handleCloseViewUserDetails } = useContext(AppContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  //handle Catch Error
  const handleCatchError = (err) => {
    try {
      handleAlert(t("lang") === "ar" ? err.response.data.arError : err.response.data.enError, "error")
    } catch (error) {
      handleAlert(t("forms.fetch.public_error.message"), "error")
    }
  }

  //Login
  const loginInitialValues = {
    username: "",
    password: "",
  }

  const loginSchema = yup.object({
    username: yup.string(t("forms.username.string")).required(t("forms.username.required")),
    password: yup.string(t("forms.password.string"))
      .min(8, t("forms.password.min"))
      .required(t("forms.password.required")),
  })

  const loginFormik = useFormik({
    initialValues: loginInitialValues,
    validationSchema: loginSchema,
    onSubmit: async (values) => {
      setLoading(true)
      await axios.post(`${server_url}/Admin/Login`, values).then((res) => {
        try {
          const token = res.data.token
          const user = res.data.user
          handleAlert(t("forms.login.success_request.message"), "success")
          dispatch(login({ token, user }))
          navigate(`${process.env.REACT_APP_HOME_ROUTE}`)
        } catch (err) {
          handleAlert(t("forms.fetch.public_error.message"), "error")
        }
      }).catch((err) => {
        handleCatchError(err)
      })
      setLoading(false)
    }
  })

  //Handle Report Dates
  const handleReportDatesInitialValues = {
    startDate: handleStartDateAndEndDate().startDate,
    endDate: handleStartDateAndEndDate().endDate
  }

  const handleReportDatesSchema = yup.object({
    startDate: yup.string(t("forms.startDate.string")).required(t("forms.startDate.required")),
    endDate: yup.string(t("forms.endDate.string")).required(t("forms.endDate.required"))
  })

  const handleReportDatesFormik = useFormik({
    initialValues: handleReportDatesInitialValues,
    validationSchema: handleReportDatesSchema,
    onSubmit: async (values) => {
      setLoading(true)
      if (option === 0) {
        await dispatch(getMostSoldProducts(values))
      } else if (option === 1) {
        await dispatch(getMostPurchasingUsers(values))
      } else if (option === 2) {
        await dispatch(getMostSellingUsers(values))
      } else if (option === 100) {
        await dispatch(getContacts(values))
      } else if (option === 101) {
        await dispatch(getComplaints(values))
      }
      setLoading(false)
    }
  })

  //Add Product
  const addProductInitialValues = {
    arName: "",
    enName: "",
    arDescription: "",
    enDescription: "",
    category: "",
    providerType: ""
  }

  const addProductSchema = yup.object({
    arName: yup.string(t("forms.arabic_name.string")).required(t("forms.arabic_name.required")),
    enName: yup.string(t("forms.english_name.string")).required(t("forms.english_name.required")),
    arDescription: yup.string(t("forms.arabic_description.string")).required(t("forms.arabic_description.required")),
    enDescription: yup.string(t("forms.english_description.string")).required(t("forms.english_description.required")),
    category: yup.string(t("forms.category.string")).required(t("forms.category.required")),
    providerType: yup.string(t("forms.providerType.string")).required(t("forms.providerType.required"))
  })

  const addProductFormik = useFormik({
    initialValues: addProductInitialValues,
    validationSchema: addProductSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      if (!values.imageURL) {
        handleAlert(t("forms.image.required"), "error")
        setLoading(false)
        return
      }
      const formData = new FormData()
      formData.append("image", values.imageURL)
      formData.append("arName", values.arName)
      formData.append("enName", values.enName)
      formData.append("arDescription", values.arDescription)
      formData.append("enDescription", values.enDescription)
      formData.append("category", values.category)
      formData.append("providerType", values.providerType)
      await axios.post(`${server_url}/Admin/CreateProduct`, formData, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then(() => {
        try {
          resetForm()
          handleAlert(t("forms.add_product_successfully.message"), "success")
          dispatch(getProducts({ start: 0, count: 10 }))
          setPages(1)
        } catch (err) {
          handleAlert(t("forms.fetch.public.error"), "error")
        }
      }).catch((err) => {
        handleCatchError(err)
      })
      setLoading(false)
    }
  })

  //Add Product
  const editProductInitialValues = {
    imageURL: productData && productData.imageURL,
    arName: productData && productData.arName,
    enName: productData && productData.enName,
    arDescription: productData && productData.arDescription,
    enDescription: productData && productData.enDescription,
    category: productData && productData.category,
    productID: productData && productData._id,
  }

  const editProductSchema = yup.object({
    arName: yup.string(t("forms.arabic_name.string")).required(t("forms.arabic_name.required")),
    enName: yup.string(t("forms.english_name.string")).required(t("forms.english_name.required")),
    arDescription: yup.string(t("forms.arabic_description.string")).required(t("forms.arabic_description.required")),
    enDescription: yup.string(t("forms.english_description.string")).required(t("forms.english_description.required")),
    category: yup.string(t("forms.category.string")).required(t("forms.category.required")),
    productID: yup.string(t("forms.productID.string")).required(t("forms.productID.required"))
  })

  const editProductFormik = useFormik({
    initialValues: editProductInitialValues,
    validationSchema: editProductSchema,
    onSubmit: async (values, { resetForm }) => {
      setLoading(true)
      if (!values.imageURL) {
        handleAlert(t("forms.image.required"), "error")
        setLoading(false)
        return
      }
      const formData = new FormData()
      formData.append("image", values.imageURL)
      formData.append("arName", values.arName)
      formData.append("enName", values.enName)
      formData.append("arDescription", values.arDescription)
      formData.append("enDescription", values.enDescription)
      formData.append("category", values.category)
      formData.append("productID", productData._id)
      await axios.put(`${server_url}/Admin/UpdateProduct`, formData, {
        headers: {
          "Authorization": `Bearer ${token}`
        }
      }).then(() => {
        try {
          resetForm()
          handleAlert(t("forms.edit_product_successfully.message"), "success")
          dispatch(getProducts({ start: 0, count: 10 }))
          handleCloseEditProductModal()
        } catch (err) {
          handleAlert(t("forms.fetch.public.error"), "error")
        }
      }).catch((err) => {
        handleCatchError(err)
      })
      setLoading(false)
    }
  })

  //Hide Product
  const handleHideProduct = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.put(`${server_url}/Admin/HideProduct?id=${proId}`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(() => {
      try {
        handleAlert(t("forms.hide_product_successfully.message"), "success")
        dispatch(getProducts({ start: 0, count: 10 }))
        handleCloseViewProductDetails()
      } catch (err) {
        handleAlert(t("forms.fetch.public.error"), "error")
      }
    }).catch((err) => {
      handleCatchError(err)
    })
    setLoading(false)
  }

  //Un Hide Product
  const handleUnHideProduct = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.put(`${server_url}/Admin/UnHideProduct?id=${proId}`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(() => {
      try {
        handleAlert(t("forms.un_hide_product_successfully.message"), "success")
        dispatch(getProducts({ start: 0, count: 10 }))
        handleCloseViewProductDetails()
      } catch (err) {
        handleAlert(t("forms.fetch.public.error"), "error")
      }
    }).catch((err) => {
      handleCatchError(err)
    })
    setLoading(false)
  }

  //Hide User
  const handleHideUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.put(`${server_url}/Admin/HideUser?id=${userId}`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(() => {
      try {
        handleAlert(t("forms.hide_user_successfully.message"), "success")
        dispatch(getUsers({ start: 0, count: 10 }))
        handleCloseViewUserDetails()
      } catch (err) {
        handleAlert(t("forms.fetch.public.error"), "error")
      }
    }).catch((err) => {
      handleCatchError(err)
    })
    setLoading(false)
  }

  //Un Hide User
  const handleUnHideUser = async (e) => {
    e.preventDefault()
    setLoading(true)
    await axios.put(`${server_url}/Admin/UnHideUser?id=${userId}`, {}, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    }).then(() => {
      try {
        handleAlert(t("forms.un_hide_user_successfully.message"), "success")
        dispatch(getUsers({ start: 0, count: 10 }))
        handleCloseViewUserDetails()
      } catch (err) {
        handleAlert(t("forms.fetch.public.error"), "error")
      }
    }).catch((err) => {
      handleCatchError(err)
    })
    setLoading(false)
  }

  return (
    <form onSubmit={type === "dashboard_login" ? loginFormik.handleSubmit : type === "handle_report_dates" ? handleReportDatesFormik.handleSubmit : type === "add_product" ? addProductFormik.handleSubmit : type === "hide_product" ? handleHideProduct : type === "unhide_product" ? handleUnHideProduct : type === "hide_user" ? handleHideUser : type === "unhide_user" ? handleUnHideUser : type === "edit_product" && editProductFormik.handleSubmit}>
      {type === "dashboard_login" ? <LoginForm formik={loginFormik} loading={loading} /> : type === "handle_report_dates" ? <HandleReportDateForm formik={handleReportDatesFormik} loading={loading} /> : type === "add_product" ? <AddProductForm formik={addProductFormik} loading={loading} /> : type === "hide_product" ? <HideProductForm loading={loading} /> : type === "unhide_product" ? <UnHideProductForm loading={loading} /> : type === "hide_user" ? <HideUserForm loading={loading} /> : type === "unhide_user" ? <UnHideUserForm loading={loading} /> : type === "edit_product" && <EditProductForm formik={editProductFormik} loading={loading} />}
    </form>
  )
}

export default Forms