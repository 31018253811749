import { BadgeRounded, MoneyRounded, PeopleRounded, RepeatRounded } from "@mui/icons-material"
import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import { useTranslation } from 'react-i18next'
import styles from "./MostUsersTable.module.scss"

const Row = ({ head, user, index }) => {
  const { t } = useTranslation()
  const mdSize = useMediaQuery("(max-width:992px)")
  const smSize = useMediaQuery("(max-width:768px)")

  return head ? (
    <Paper className={`grid jcs aic pad20 br10 ${styles.row} ${styles.head_row} `}>
      <Typography variant="h6" className={`fw700`}># Id</Typography>
      <Box className={`flex aic g5`}>
        {!mdSize && <BadgeRounded />}
        <Typography variant="h6" className={`fw700`}>{t("name")}</Typography>
      </Box>
      {!mdSize && <Box className={`flex aic g5`}>
        {!mdSize && <RepeatRounded />}
        <Typography variant="h6" className={`fw700`}>{t("repeats")}</Typography>
      </Box>}
      {!smSize && <Box className={`flex aic g5`}>
        {!mdSize && <PeopleRounded />}
        <Typography variant="h6" className={`fw700`}>{t("client_type")}</Typography>
      </Box>}
      <Box className={`flex aic g5`}>
        {!mdSize && <MoneyRounded />}
        <Typography variant="h6" className={`fw700`}>{t("total_paid")}</Typography>
      </Box>
    </Paper>
  ) : (
    <Paper className={`${user.isHidden && styles.hidden} ${styles.row} pad20 br10 grid jcs aifs`}>
      <Typography variant="h6">{`# ${index + 1}`}</Typography>
      <Box className={`flex jcfs aic g10 ${styles.user_name}`}>
        <Typography variant="h6">{t("lang") === "ar" ? user.user.arName : user.user.enName}</Typography>
      </Box>
      {!mdSize && <Typography variant="h6">{user.repeats}</Typography>}
      {!smSize && <Typography variant="h6">{t("lang") === "ar" ? user.user.type.arName : user.user.type.enName}</Typography>}
      <Typography variant="h6">{user.totalPaid}</Typography>
    </Paper>
  )
}

export default Row