import { AddRounded } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useDispatch, useSelector } from "react-redux";
import LoadButton from "../components/LoadButton/LoadButton";
import UploadImage from "../components/UploadImage/UploadImage";
import { AppContext } from "../context/AppContext";
import { PrimaryBox } from "../muiCustomize/PrimaryBox";
import { PrimaryButton } from "../muiCustomize/PrimaryButton";
import { PrimaryContainer } from "../muiCustomize/PrimaryContainer";
import { PrimaryTextField } from "../muiCustomize/PrimaryTextField";
import { getCategories } from "../store/categoriesSlice";
import { getUserTypes } from "../store/userTypesSlice";

const AddProductForm = ({ loading, formik }) => {
  const { t } = useTranslation()
  const { productImageForCreate } = useContext(AppContext)
  const { categories } = useSelector((state) => state.categories)
  const { userTypes } = useSelector((state) => state.userTypes)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getUserTypes())
  }, [dispatch])

  useEffect(() => {
    formik.values.imageURL = productImageForCreate
  }, [productImageForCreate, formik])

  return (
    <PrimaryBox>
      <PrimaryContainer className={`grid jcs aic g50`}>
        <Box className={`add_product_title flex jcc aic g10 center_rel_x`}>
          <AddRounded />
          <Typography variant="h4" className="fw700 tac" >{t("add_product.title")}</Typography>
        </Box>
        <Box className={`grid jcs aic g30`}>
          <Box className={`grid  jcs aic g10`} sx={{ width: "100%" }}>
            <Typography variant="h6">{t("forms.product_image.label")}</Typography>
            <UploadImage type={"add_product"} />
            {productImageForCreate &&
              (
                <Box className={`flex jcc aic product_image_box`}>
                  <LazyLoadImage src={URL.createObjectURL(productImageForCreate)} alt={"product"} />
                </Box>
              )
            }
          </Box>
          <Box className={`flex jcsb aic g30 sm_wrap`}>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.category.label")}</Typography>
              <PrimaryTextField
                id="category"
                name="category"
                select
                fullWidth
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formik.values.category}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.category && Boolean(formik.errors.category)}
                helperText={formik.touched.category && formik.errors.category}
              >
                <option key={-1} value={""}>
                </option>
                {
                  categories && categories.map((cat, i) => (
                    <option key={i} value={cat._id}>
                      {
                        t("lang") === "ar" ? cat.arName : cat.enName
                      }
                    </option>
                  ))
                }
              </PrimaryTextField>
            </Box>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.providerType.label")}</Typography>
              <PrimaryTextField
                id="providerType"
                name="providerType"
                select
                fullWidth
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                value={formik.values.providerType}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.providerType && Boolean(formik.errors.category)}
                helperText={formik.touched.providerType && formik.errors.providerType}
              >
                <option key={-1} value={""}>
                </option>
                {
                  userTypes && userTypes.slice(1).map((type, i) => (
                    <option key={i} value={type._id}>
                      {
                        t("lang") === "ar" ? type.arName : type.enName
                      }
                    </option>
                  ))
                }
              </PrimaryTextField>
            </Box>
          </Box>
          <Box className={`flex jcsb aic g30 sm_wrap`}>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.arabic_name.label")}</Typography>
              <PrimaryTextField
                fullWidth
                variant="outlined"
                type="text"
                id="arName"
                name="arName"
                value={formik.values.arName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.arName && Boolean(formik.errors.arName)}
                helperText={formik.touched.arName && formik.errors.arName}
              />
            </Box>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.english_name.label")}</Typography>
              <PrimaryTextField
                fullWidth
                variant="outlined"
                type="text"
                id="enName"
                name="enName"
                value={formik.values.enName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enName && Boolean(formik.errors.enName)}
                helperText={formik.touched.enName && formik.errors.enName}
              />
            </Box>
          </Box>
          <Box className={`flex jcsb aic g30 md_wrap`}>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.arabic_description.label")}</Typography>
              <PrimaryTextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                type="text"
                id="arDescription"
                name="arDescription"
                value={formik.values.arDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.arDescription && Boolean(formik.errors.arDescription)}
                helperText={formik.touched.arDescription && formik.errors.arDescription}
              />
            </Box>
            <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
              <Typography variant="h6">{t("forms.english_description.label")}</Typography>
              <PrimaryTextField
                multiline
                rows={4}
                fullWidth
                variant="outlined"
                type="text"
                id="enDescription"
                name="enDescription"
                value={formik.values.enDescription}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.enDescription && Boolean(formik.errors.enDescription)}
                helperText={formik.touched.enDescription && formik.errors.enDescription}
              />
            </Box>
          </Box>
          <LoadButton loading={loading}>
            <PrimaryButton type={"submit"}>{t("forms.add_product.button")}</PrimaryButton>
          </LoadButton>
        </Box>
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default AddProductForm
