import { AddRounded, ChevronLeft, ChevronRight, EqualizerRounded, Inventory2Rounded, LogoutRounded, PeopleOutlineRounded, PhoneCallbackRounded, ReportRounded } from "@mui/icons-material";
import { Box, Divider, Drawer, Typography, useMediaQuery } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { AppContext } from '../../context/AppContext';
import { PrimaryButton } from "../../muiCustomize/PrimaryButton";
import { PrimaryIconButton } from "../../muiCustomize/PrimaryIconButton";
import { SecondaryButton } from "../../muiCustomize/SecondaryButton";
import { logout } from "../../store/authSlice";
import LanguagesMenu from "../Header/LanguagesMenu";
import Logo from "../Logo/Logo";
import styles from "./Sidebar.module.scss";

const Sidebar = () => {
  const { handleDrawerClose, setPages, setOption, openDrawer, drawerWidth } = useContext(AppContext)
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const mdSize = useMediaQuery("(max-width:992px)")

  // const closedDrawerList = (
  //   <Box className={`${styles.sidebar_list} pad20 grid jcs aic g30`}>
  //     <Box className={`flex jcc aic`}>
  //       <Tooltip title={"Users"}>
  //         <PrimaryIconButton>
  //           <PeopleOutlineRounded />
  //         </PrimaryIconButton>
  //       </Tooltip>
  //     </Box>
  //     <Box className={`flex jcc aic`}>
  //       <Tooltip title={"Products"}>
  //         <PrimaryIconButton>
  //           <Inventory2Rounded />
  //         </PrimaryIconButton>
  //       </Tooltip>
  //     </Box>
  //     <Box className={`flex jcc aic`}>
  //       <Tooltip title={"Analysis"}>
  //         <PrimaryIconButton>
  //           <EqualizerRounded />
  //         </PrimaryIconButton>
  //       </Tooltip>
  //     </Box>
  //     <Box className={`flex jcc aic`}>
  //       <Tooltip title={"Contacts"}>
  //         <PrimaryIconButton>
  //           <PhoneCallbackRounded />
  //         </PrimaryIconButton>
  //       </Tooltip>
  //     </Box>
  //     <Box className={`flex jcc aic`}>
  //       <Tooltip title={"Complaints"}>
  //         <PrimaryIconButton>
  //           <ReportRounded />
  //         </PrimaryIconButton>
  //       </Tooltip>
  //     </Box>
  //   </Box>
  // )

  const handleGetUsers = () => {
    setPages(0)
    handleDrawerClose()
  }

  const handleGetProducts = () => {
    setPages(1)
    handleDrawerClose()
  }

  const handleAddProduct = () => {
    setPages(2)
    handleDrawerClose()
  }

  const handleGetMostSoldProducts = () => {
    setPages(3)
    setOption(0)
    handleDrawerClose()
  }

  const handleGetContacts = () => {
    setPages(4)
    setOption(100)
    handleDrawerClose()
  }

  const handleGetComplaints = () => {
    setPages(5)
    setOption(101)
    handleDrawerClose()
  }

  const handleLogout = () => {
    dispatch(logout())
    navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`)
  }

  const openedDrawerList = (
    <Box className={`${styles.sidebar_list} ${styles.sidebar_list_options} pad20 grid jcs aic g30`}>

      <PrimaryButton onClick={handleGetUsers} fullWidth className={`flex jcfs aic g20`}>
        <PeopleOutlineRounded />
        <Typography variant="h6" >{t("sidebar.users.text")}</Typography>
      </PrimaryButton>
      <PrimaryButton onClick={handleGetProducts} fullWidth className={`flex jcfs aic g20`}>
        <Inventory2Rounded />
        <Typography variant="h6" >{t("sidebar.products.text")}</Typography>
      </PrimaryButton>
      <PrimaryButton onClick={handleAddProduct} fullWidth className={`flex jcfs aic g20`}>
        <AddRounded />
        <Typography variant="h6" >{t("sidebar.add_product.text")}</Typography>
      </PrimaryButton>
      <PrimaryButton onClick={handleGetMostSoldProducts} fullWidth className={`flex jcfs aic g20`}>
        <EqualizerRounded />
        <Typography variant="h6" >{t("sidebar.analysis_reports.text")}</Typography>
      </PrimaryButton>
      <PrimaryButton onClick={handleGetContacts} fullWidth className={`flex jcfs aic g20`}>
        <PhoneCallbackRounded />
        <Typography variant="h6" >{t("sidebar.contacts.text")}</Typography>
      </PrimaryButton>
      <PrimaryButton onClick={handleGetComplaints} fullWidth className={`flex jcfs aic g20`}>
        <ReportRounded />
        <Typography variant="h6" >{t("sidebar.complaints.text")}</Typography>
      </PrimaryButton>
      {
        mdSize && (<>
          <LanguagesMenu />
          <SecondaryButton onClick={handleLogout} fullWidth className={`flex jcfs aic g20`}>
            <LogoutRounded />
            <Typography variant="h6" >{t("sidebar.logout.text")}</Typography>
          </SecondaryButton>
        </>)
      }
    </Box>
  )

  return (
    <Drawer open={openDrawer} onClose={handleDrawerClose} anchor={t("lang") === "en" ? "left" : "right"} sx={{ zIndex: openDrawer ? "1200" : "1000", width: `${drawerWidth}px` }} className={`${styles.drawer}`}>
      <Box className={`flex jcsb aic g30 ${styles.drawer_header}`}>
        {openDrawer && (
          <Box className={`flex jcc aic g10`}>
            <Logo />
            <Typography variant="h5" className={`fw700 tac`} noWrap>
              {t("header.title")}
            </Typography>
          </Box>
        )}
        <PrimaryIconButton onClick={handleDrawerClose}>
          {t("dir") === 'rtl' ? <ChevronRight /> : <ChevronLeft />}
        </PrimaryIconButton>
      </Box>
      <Divider sx={{ borderWidth: "2px" }} />
      {openedDrawerList}
    </Drawer>
  )
}

export default Sidebar