import { Box, Typography } from "@mui/material"
import { useContext } from "react"
import { useTranslation } from "react-i18next"
import { AppContext } from "../../context/AppContext"
import { SecondaryButton } from "../../muiCustomize/SecondaryButton"
import LoadButton from "../LoadButton/LoadButton"
import { LoadingTable } from "../LoadingTable/LoadingTable"
import Row from "./Row"
import styles from "./SellersTable.module.scss"

const SellersTable = ({ id, handlePagination, productSeller, isLoading, last }) => {
  const { t } = useTranslation()
  const { viewMoreLoading } = useContext(AppContext)

  return (
    <Box className={`grid jcs aic g30 ${styles.users_table}`}>
      <Row head={true} />
      {
        isLoading ? (<LoadingTable />) : (productSeller && productSeller.length > 0) ? productSeller.map((user, i) => (
          <Row user={user} key={i} index={i} />
        )) : (<Typography variant="h5" className="tac fw700" sx={{ color: (theme) => theme.palette.gray }} >{t("users.no_users.title")}</Typography>)
      }
      {!last && <LoadButton loading={viewMoreLoading}>
        <SecondaryButton onClick={() => handlePagination(id)}>{t("view_more.button")}</SecondaryButton>
      </LoadButton>}
    </Box>
  )
}

export default SellersTable