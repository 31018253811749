import { createSlice } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'


const initialState = {
  token: null,
  user: null,
  signed: false
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      Cookies.set(`${process.env.REACT_APP_TOKEN_NAME}`, action.payload.token, { expires: 30 })
      Cookies.set(`${process.env.REACT_APP_USER_NAME}`, JSON.stringify(action.payload.user), { expires: 30 })
      state.token = action.payload.token
      state.user = action.payload.user
      state.signed = true
    },
    logout: (state) => {
      Cookies.remove(`${process.env.REACT_APP_TOKEN_NAME}`)
      Cookies.remove(`${process.env.REACT_APP_USER_NAME}`)
      state.token = null
      state.user = null
      state.signed = false
    },
    setAuth: (state, action) => {
      state.user = action.payload.user
      state.token = action.payload.token
      if (action.payload.token && action.payload.user) {
        state.signed = true
      }
    }
  }
})


export const { login, logout, setAuth } = authSlice.actions
export default authSlice.reducer