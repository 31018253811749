import { AddReactionRounded, DescriptionRounded, DriveFileRenameOutlineRounded, EditRounded, RemoveRedEyeRounded } from "@mui/icons-material"
import { Box, Paper, Typography, useMediaQuery } from '@mui/material'
import { useContext } from "react"
import { useTranslation } from 'react-i18next'
import { AppContext } from "../../context/AppContext"
import { handleFileOrPath } from "../../functions/handleFileOrPath"
import { PrimaryIconButton } from "../../muiCustomize/PrimaryIconButton"
import { SecondaryIconButton } from "../../muiCustomize/SecondaryIconButton"
import styles from "./ProductsTable.module.scss"

const Row = ({ head, product, index }) => {
  const { t } = useTranslation()
  const { handleOpenViewProductDetails, handleOpenEditProductModal } = useContext(AppContext)
  const mdSize = useMediaQuery("(max-width:992px)")
  const smSize = useMediaQuery("(max-width:768px)")

  const handleViewProductDetails = () => {
    handleOpenViewProductDetails(product)
  }

  const handleEditProduct = (product) => {
    handleOpenEditProductModal(product)
  }

  return head ? (
    <Paper className={`grid jcs aic pad20 br10 ${styles.row} ${styles.head_row} `}>
      <Typography variant="h6" className={`fw700`}># Id</Typography>
      <Box className={`flex aic g5`}>
        {!mdSize && <DriveFileRenameOutlineRounded />}
        <Typography variant="h6" className={`fw700`}>{t("name")}</Typography>
      </Box>
      {!smSize && <Box className={`flex aic g5`}>
        {!mdSize && <DescriptionRounded />}
        <Typography variant="h6" className={`fw700`}>{t("description")}</Typography>
      </Box>}
      <Box className={`flex aic g5`}>
        {!mdSize && <AddReactionRounded />}
        <Typography variant="h6" className={`fw700`}>{t("actions")}</Typography>
      </Box>
    </Paper>
  ) : (
    <Paper className={`${styles.row} ${product.isRemoved && styles.hidden} pad20 br10 grid jcs aic`}>
      <Typography variant="h6">{`# ${index + 1}`}</Typography>
      <Box className={`flex jcfs aic g10 ${styles.product_name}`}>
        {!mdSize && <Box className={`flex jcc aic ${styles.product_image_box}`} sx={{ backgroundImage: `url('${handleFileOrPath(product.imageURL)}')` }} />}
        <Typography variant="h6">{t("lang") === "ar" ? product.arName : product.enName}</Typography>
      </Box>
      {!smSize && <Typography variant="h6">{t("lang") === "ar" ? product.arDescription : product.enDescription}</Typography>}
      <Box className={`flex jcs aic g30 flex_wrap`}>
        <PrimaryIconButton onClick={() => handleEditProduct(product)}>
          <EditRounded />
        </PrimaryIconButton>
        <SecondaryIconButton onClick={handleViewProductDetails}>
          <RemoveRedEyeRounded />
        </SecondaryIconButton>
      </Box>
    </Paper>
  )
}

export default Row