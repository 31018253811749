import { PeopleOutlineRounded } from "@mui/icons-material"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import Title from "../../components/Title/Title"
import UsersTable from "../../components/UsersTable/UsersTable"
import { AppContext } from "../../context/AppContext"
import { PrimaryBox } from "../../muiCustomize/PrimaryBox"
import { PrimaryContainer } from "../../muiCustomize/PrimaryContainer"
import { getUsers } from "../../store/usersSlice"
import styles from "./UsersSection.module.scss"

const UsersSection = () => {
  const dispatch = useDispatch()
  const { setViewMoreLoading } = useContext(AppContext)
  const { users, isLoading, index, last } = useSelector((state) => state.users)

  const handlePagination = async () => {
    setViewMoreLoading(true)
    await dispatch(getUsers({ start: index + 1, count: 10 }))
    setViewMoreLoading(false)
  }
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getUsers({ start: 0, count: 10 }))
  }, [dispatch])

  return (
    <PrimaryBox>
      <PrimaryContainer className={`${styles.users_section_contain} grid jcs aic g30`}>
        <Title icon={<PeopleOutlineRounded />} title={t("users.title")} />
        <UsersTable handlePagination={handlePagination} users={users} isLoading={isLoading} last={last} />
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default UsersSection