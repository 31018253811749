import { Box, Modal, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ComplaintDetails from '../components/ComplaintsTable/ComplaintDetails';
import { AppContext } from '../context/AppContext';
import "./Modals.scss";

export const ComplaintViewModal = () => {
  const { openViewComplaintDetails, handleCloseViewComplaintDetails } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Modal
      open={openViewComplaintDetails}
      onClose={handleCloseViewComplaintDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`modal br10 pad20 center_abs_x_y grid jcs aic g30`}>
        <Typography variant='h5' className={`fw700 tac`} sx={{ color: (theme) => theme.palette.primary.main }} >{t("complaint.data")}</Typography>
        <ComplaintDetails />
      </Box>
    </Modal>
  )
}
