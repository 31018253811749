import { PrimaryBox } from "../muiCustomize/PrimaryBox"
import { PrimaryContainer } from "../muiCustomize/PrimaryContainer"
import LoginSection from "../sections/LoginSection/LoginSection"

const Login = () => {
  return (
    <PrimaryBox>
      <PrimaryContainer className={`flex jcc aic`}>
        <LoginSection />
      </PrimaryContainer>
    </PrimaryBox>
  )
}

export default Login