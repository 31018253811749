import { ReportRounded } from "@mui/icons-material"
import { useContext, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import ComplaintsTable from "../../components/ComplaintsTable/ComplaintsTable"
import Title from "../../components/Title/Title"
import { AppContext } from "../../context/AppContext"
import Forms from "../../forms/Forms"
import { PrimaryBox } from "../../muiCustomize/PrimaryBox"
import { PrimaryContainer } from "../../muiCustomize/PrimaryContainer"
import { getComplaints } from "../../store/complaintsSlice"
import styles from "./ComplaintsSection.module.scss"

export const ComplaintsSection = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { startDate, endDate } = useContext(AppContext)

  useEffect(() => {
    dispatch(getComplaints({ startDate, endDate, start: 0, count: 10 }))
  }, [dispatch, startDate, endDate])
  return (
    <PrimaryBox>
      <PrimaryContainer className={`${styles.complaints_section_contain} grid jcs aic g30`}>
        <Forms type={"handle_report_dates"} />
        <Title icon={<ReportRounded />} title={t("complaints.title")} />
        <ComplaintsTable />
      </PrimaryContainer>
    </PrimaryBox>
  )
}
