import { Box, Modal, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import ContactDetails from '../components/ContactsTable/ContactDetails';
import { AppContext } from '../context/AppContext';
import "./Modals.scss";

export const ContactViewModal = () => {
  const { openViewContactDetails, handleCloseViewContactDetails } = useContext(AppContext)
  const { t } = useTranslation()

  return (
    <Modal
      open={openViewContactDetails}
      onClose={handleCloseViewContactDetails}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box className={`modal br10 pad20 center_abs_x_y grid jcs aic g30`}>
        <Typography variant='h5' className={`fw700 tac`} sx={{ color: (theme) => theme.palette.primary.main }} >{t("contact.data")}</Typography>
        <ContactDetails />
      </Box>
    </Modal>
  )
}
