import { Box, Typography } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import { useDispatch, useSelector } from 'react-redux'
import LoadButton from '../components/LoadButton/LoadButton'
import UploadImage from '../components/UploadImage/UploadImage'
import { AppContext } from '../context/AppContext'
import { handleFileOrPath } from '../functions/handleFileOrPath'
import { DeleteButton } from '../muiCustomize/DeleteButton'
import { PrimaryButton } from '../muiCustomize/PrimaryButton'
import { PrimaryTextField } from '../muiCustomize/PrimaryTextField'
import { getCategories } from '../store/categoriesSlice'
import { getUserTypes } from '../store/userTypesSlice'

const EditProductForm = ({ loading, formik }) => {
  const { categories } = useSelector((state) => state.categories)
  const { productImageForEdit, handleCloseEditProductModal } = useContext(AppContext)
  const dispatch = useDispatch()
  const { t } = useTranslation()

  useEffect(() => {
    dispatch(getCategories())
    dispatch(getUserTypes())
  }, [dispatch])

  useEffect(() => {
    if (productImageForEdit) {
      formik.values.imageURL = productImageForEdit
    }
  }, [productImageForEdit, formik])

  return (
    <Box className={`grid jcs aic g30`}>
      <Box className={`grid jcs aic g10`}>
        <Typography>{t("forms.edit_user_product.edit_image.label")}</Typography>
        <UploadImage type={"edit_product"} />
        <Box className={`flex jcc aic product_image_box`}>
          <LazyLoadImage src={productImageForEdit ? handleFileOrPath(productImageForEdit) : handleFileOrPath(formik.values.imageURL)} alt={"product"} />
        </Box>
      </Box>
      <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
        <Typography variant="h6">{t("forms.category.label")}</Typography>
        <PrimaryTextField
          id="category"
          name="category"
          select
          fullWidth
          SelectProps={{
            native: true,
          }}
          variant="outlined"
          value={formik.values.category}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.category && Boolean(formik.errors.category)}
          helperText={formik.touched.category && formik.errors.category}
        >
          <option key={-1} value={""}>
          </option>
          {
            categories && categories.map((cat, i) => (
              <option key={i} value={cat._id}>
                {
                  t("lang") === "ar" ? cat.arName : cat.enName
                }
              </option>
            ))
          }
        </PrimaryTextField>
      </Box>
      <Box className={`flex jcsb aic g30 sm_wrap`}>
        <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
          <Typography variant="h6">{t("arabic_name")}</Typography>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="arName"
            name="arName"
            value={formik.values.arName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.arName && Boolean(formik.errors.arName)}
            helperText={formik.touched.arName && formik.errors.arName}
          />
        </Box>
        <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
          <Typography variant="h6">{t("english_name")}</Typography>
          <PrimaryTextField
            fullWidth
            variant="outlined"
            type="text"
            id="enName"
            name="enName"
            value={formik.values.enName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.enName && Boolean(formik.errors.enName)}
            helperText={formik.touched.enName && formik.errors.enName}
          />
        </Box>
      </Box>
      <Box className={`flex jcsb aic g30 md_wrap`}>
        <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
          <Typography variant="h6">{t("arabic_description")}</Typography>
          <PrimaryTextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            type="text"
            id="arDescription"
            name="arDescription"
            value={formik.values.arDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.arDescription && Boolean(formik.errors.arDescription)}
            helperText={formik.touched.arDescription && formik.errors.arDescription}
          />
        </Box>
        <Box className={`grid jcs aic g10`} sx={{ width: "100%" }}>
          <Typography variant="h6">{t("english_description")}</Typography>
          <PrimaryTextField
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            type="text"
            id="enDescription"
            name="enDescription"
            value={formik.values.enDescription}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.enDescription && Boolean(formik.errors.enDescription)}
            helperText={formik.touched.enDescription && formik.errors.enDescription}
          />
        </Box>
      </Box>
      <Box className={`flex jcsb aic g30`}>
        <LoadButton loading={loading}>
          <PrimaryButton fullWidth type={"submit"}>{t("edit.button")}</PrimaryButton>
        </LoadButton>
        <DeleteButton onClick={handleCloseEditProductModal} fullWidth>{t("cancel.button")}</DeleteButton>
      </Box>
    </Box>
  )
}

export default EditProductForm