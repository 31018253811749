import { useContext, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import MostUsersTable from "../../components/MostUsersTable/MostUsersTable"
import { AppContext } from "../../context/AppContext"
import { getMostPurchasingUsers } from "../../store/mostPurchasingUsersSlice"

const MostPurchasingUsersSection = () => {
  const dispatch = useDispatch()
  const { mostPurchasingUsers, isLoading, index, last } = useSelector((state) => state.mostPurchasingUsers)

  const handlePagination = () => {
    dispatch(getMostPurchasingUsers({ startDate, endDate, start: index + 1, count: 10 }))
  }
  const { startDate, endDate } = useContext(AppContext)
  useEffect(() => {
    dispatch(getMostPurchasingUsers({ startDate, endDate, start: 0, count: 10 }))
  }, [dispatch, startDate, endDate])
  return (
    <MostUsersTable handlePagination={handlePagination} users={mostPurchasingUsers} isLoading={isLoading} last={last} />
  )
}

export default MostPurchasingUsersSection