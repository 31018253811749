import Box from '@mui/material/Box';
import { ThemeProvider } from '@mui/material/styles';
import Cookies from "js-cookie";
import { useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Theme } from './Theme';
import Footer from './components/Footer/Footer';
import Header from './components/Header/Header';
import Sidebar from './components/Sidebar/Sidebar';
import AppProvider from './context/AppContext';
import { handleAlert } from './functions/handleAlert';
import i18n from "./i18n";
import { ComplaintViewModal } from './models/ComplaintViewModal';
import { ContactViewModal } from './models/ContactViewModal';
import { EditProductModal } from './models/EditProductModal';
import { ProductViewModal } from './models/ProductViewModal';
import { UserViewModal } from './models/UserViewModal';
import { logout, setAuth } from "./store/authSlice";

export default function App() {
  const dispatch  = useDispatch()
  const {t} = useTranslation()
  const navigate= useNavigate()
  const {pathname} = useLocation()

  useEffect(()=>{
    try{
      const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
      const user = Cookies.get(process.env.REACT_APP_USER_NAME);
      if(token && user){
        dispatch(setAuth({token,user:JSON.parse(user)}))
      }else{
        dispatch(logout())
        navigate(`${process.env.REACT_APP_LOGIN_ROUTE}`)
      }
    }catch(err){
      handleAlert(err.message,"error")
    }
  },[dispatch,navigate])

  useEffect(()=>{
    if(localStorage.getItem("dagagino_dashboard_lang")){
      i18n.changeLanguage(localStorage.getItem("dagagino_dashboard_lang"))
      document.documentElement.lang = localStorage.getItem("dagagino_dashboard_lang");
      if(localStorage.getItem("dagagino_dashboard_lang") === "ar"){
        document.documentElement.dir = "rtl";
      }else{
        document.documentElement.dir = "ltr";
      }
    }else{
      localStorage.setItem("dagagino_dashboard_lang","ar")
      i18n.changeLanguage("ar")
      document.documentElement.lang = "ar";
      document.documentElement.dir = "rtl";
    }
  },[])

  return (
    <AppProvider>
      <ThemeProvider theme={Theme("light",t("dir"))}>
          {pathname === "/login" ?(
            <Box component={"main"} className={`grid`}>
              <Header/>
              <Outlet/>
              <Footer/>
            </Box>
            ) : (
            <Box component={"main"} className={`flex`}>
              <Sidebar/>
              <Box sx={{width:"100%"}} className={`relative grid jcs aic acsb`}>
                <Header/>
                <Outlet/>
                <ProductViewModal/>
                <ContactViewModal/>
                <ComplaintViewModal/>
                <UserViewModal/>
                <EditProductModal/>
                <Footer/>
              </Box>
            </Box>
          )}
          <Toaster/>
      </ThemeProvider>
    </AppProvider>
  );
}