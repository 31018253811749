import { createContext, useState } from "react";
import { handleStartDateAndEndDate } from "../functions/handleStartDateAndEndDate";

export const AppContext = createContext()


const AppProvider = ({children}) => {
  const [openViewUserDetails , setOpenViewUserDetails] = useState(false)
  const [openViewProductDetails , setOpenViewProductDetails] = useState(false)
  const [openViewContactDetails , setOpenViewContactDetails] = useState(false)
  const [openViewComplaintDetails , setOpenViewComplaintDetails] = useState(false)
  const [openEditProductModal,setOpenEditProductModal] = useState(false)
  const [userData , setUserData] = useState(false)
  const [productData , setProductData] = useState(false)
  const [contactData , setContactData] = useState(false)
  const [complaintData , setComplaintData] = useState(false)
  const [productImageForCreate,setProductImageForCreate] = useState(null)
  const [productImageForEdit,setProductImageForEdit] = useState(null)
  const [loadingUploading,setLoadingUploading] = useState(false)
  const [openDrawer, setOpenDrawer] = useState(false);
  const [drawerWidth,setDrawerWidth]= useState(90)
  const [pages,setPages] = useState(0)
  const [option,setOption] = useState(0)
  const [viewMoreLoading,setViewMoreLoading] = useState(false)
  const [startDate, setStartDate] = useState(handleStartDateAndEndDate().startDate)
  const [endDate, setEndDate] = useState(handleStartDateAndEndDate().endDate)

  // Drawer (Sidebar)
  const handleDrawerOpen = () => {
    setOpenDrawer(true);
    setDrawerWidth(450)
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setDrawerWidth(90)
  };

  // View User
  const handleOpenViewUserDetails =(user)=>{
    setOpenViewUserDetails(true)
    setUserData(user)
  }

  const handleCloseViewUserDetails =()=>{
    setOpenViewUserDetails(false)
  }

  // View Product
  const handleOpenViewProductDetails =(product)=>{
    setOpenViewProductDetails(true)
    setProductData(product)
  }

  const handleCloseViewProductDetails =()=>{
    setOpenViewProductDetails(false)
  }

  // View Contact
  const handleOpenViewContactDetails =(contact)=>{
    setOpenViewContactDetails(true)
    setContactData(contact)
  }

  const handleCloseViewContactDetails =()=>{
    setOpenViewContactDetails(false)
  }

  // View Complaint
  const handleOpenViewComplaintDetails =(complaint)=>{
    setOpenViewComplaintDetails(true)
    setComplaintData(complaint)
  }

  const handleCloseViewComplaintDetails =()=>{
    setOpenViewComplaintDetails(false)
  }
  
  // Edit Product
  const handleOpenEditProductModal =(product)=>{
    setOpenEditProductModal(true)
    setProductData(product)
  }

  const handleCloseEditProductModal =()=>{
    setOpenEditProductModal(false)
    setProductImageForEdit(null)
  }
  return (
    <AppContext.Provider value={{viewMoreLoading,setViewMoreLoading,contactData,complaintData,openViewComplaintDetails,openViewContactDetails,handleOpenViewContactDetails,handleCloseViewContactDetails,handleCloseViewComplaintDetails,handleOpenViewComplaintDetails, productImageForEdit,setProductImageForEdit, handleOpenEditProductModal,handleCloseEditProductModal,openEditProductModal,productData,handleCloseViewProductDetails,handleOpenViewProductDetails,openViewProductDetails,endDate, setEndDate,startDate, setStartDate,userData,handleCloseViewUserDetails,handleOpenViewUserDetails,openViewUserDetails,loadingUploading,setLoadingUploading,productImageForCreate,setProductImageForCreate,setOption,option,pages,setPages,openDrawer,drawerWidth,handleDrawerOpen,handleDrawerClose}}>{children}</AppContext.Provider>
  )
}

export default AppProvider