import { Box, Typography } from "@mui/material";
import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { AppContext } from "../../context/AppContext";
import { handleFileOrPath } from "../../functions/handleFileOrPath";
import styles from "./ComplaintsTable.module.scss";

const ComplaintDetails = () => {
  const { complaintData } = useContext(AppContext)
  const { user, message } = complaintData
  const { t } = useTranslation()
  return (
    <Box className={`${styles.details} grid jcs aic g30`}>
      <Box className={`${styles.data_image_box} flex jcc aic`}>
        <LazyLoadImage src={`${handleFileOrPath(user.cover)}`} alt={"user"} />
      </Box>
      <Box className={`${styles.details_box} grid jcs aifs g30`}>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("name")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{t("lang") === "ar" ? user.arName : user.enName}</Typography>
        </Box>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("email")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{user.email}</Typography>
        </Box>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("phone")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{user.phone}</Typography>
        </Box>
      </Box>
      <Box className={`${styles.details_box} flex jcs aifs g30`}>
        <Box className={`${styles.data_box} pad20 br6 flex jcs aic flex_wrap g10`}>
          <Typography sx={{ color: (theme) => theme.palette.dark }} variant="h6">{t("message")} : </Typography>
          <Typography sx={{ color: (theme) => theme.palette.primary.main }} variant="h6">{message}</Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default ComplaintDetails