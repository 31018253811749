import { RemoveRedEyeRounded } from "@mui/icons-material";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import LoadButton from "../components/LoadButton/LoadButton";
import { SecondaryButton } from "../muiCustomize/SecondaryButton";

const UnHideUserForm = ({ loading }) => {
  const { t } = useTranslation()
  return (
    <LoadButton loading={loading}>
      <SecondaryButton type={"submit"} fullWidth className={`flex jcc aic g10`}>
        <Typography variant="h6">{t("user.active")}</Typography>
        <RemoveRedEyeRounded />
      </SecondaryButton>
    </LoadButton>
  )
}

export default UnHideUserForm
