import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Cookies from 'js-cookie';

export const getProducts = createAsyncThunk("products/getProducts",async(args)=>{
  const token = Cookies.get(process.env.REACT_APP_TOKEN_NAME);
  const res = await axios.post(`${process.env.REACT_APP_SERVER_URL}/Admin/Products`,{start:(args?.start)*10 || 0,count:10},{
    headers:{
      Authorization:`Bearer ${token}`
    }
  })
  return { data: res.data.data, index: args.start, last: res.data.data.length < 10 }
})


const initialState = {
  products: null,
  isLoading:true,
  index: 0,
  last: false
}

export const productsSlice = createSlice({
  name: 'products',
  initialState,
  reducers: {
  },
  extraReducers:(builder)=>{
    builder.addCase(getProducts.fulfilled, (state, { payload }) => {
      if (payload.index > 0) {
        state.products.push(...payload.data)
      } else {
        state.products = payload.data
      }
      state.index = payload.index
      state.last = payload.last
      state.isLoading = false
    })
    builder.addCase(getProducts.rejected, (state, action) => {
      state.isLoading = true
      if (action.payload) {
        console.log(action.payload.errorMessage)
      } else {
        console.log(action.error.message)
      }
    })
  }
})


export default productsSlice.reducer