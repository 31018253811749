import { useContext } from "react"
import { AppContext } from "../../context/AppContext"
import Forms from "../../forms/Forms"
import AnalysisReportsOptions from "../../tabs/AnalysisReportsOptions/AnalysisReportsOptions"
import { ComplaintsSection } from "../ComplaintsSection/ComplaintsSection"
import ContactsSection from "../ContactsSection/ContactsSection"
import ProductsSection from "../ProductsSection/ProductsSection"
import UsersSection from "../UsersSection/UsersSection"

const HomeSection = () => {
  const { pages } = useContext(AppContext)
  return (
    <>
      {pages === 0 ? <UsersSection /> : pages === 1 ? <ProductsSection /> : pages === 2 ? <Forms type={"add_product"} /> : pages === 3 ? <AnalysisReportsOptions /> : pages === 4 ? <ContactsSection /> : pages === 5 && <ComplaintsSection />}
    </>
  )
}

export default HomeSection